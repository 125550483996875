<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">数实融合数字企业培育计划</div>
      <div class="sub-title">加速数字经济产业集聚，探索区域经济新建设</div>
      <div class="large-paragraph-describe">
        全球蛙深入探索区域经济建设新思路，通过进行企业与品牌的数字化升级，打造数字经济发展高地，促进数实融合。借助独具价值的数字化解决方案，帮助企业与品牌高质量发展，推动产业升级，为提振地方消费服务带来更多创新思路与实现可能。
      </div>
    </div>
    <img
      class="large-image-h398"
      v-for="src in srcModules"
      :src="src"
      :key="src"
    />
    <action-sheet module="新农村数字企业培育">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    srcModules: [
      require("@/image/region/img_platform_cultivate_village@3x.png"),
      require("@/image/region/img_intelligence_cultivate_village@3x.png"),
      require("@/image/region/img_ability_cultivate_village@3x.png"),
      require("@/image/region/img_nationwide_cultivate_village@3x.png"),
      require("@/image/region/img_supply_cultivate_village@3x.png"),
      require("@/image/region/img_product_cultivate_village@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-paragraph-describe {
  margin-bottom: 20px !important;
}
</style>
